define('ember-highlight-js/component', ['exports', 'ember', 'highlight.js'], function (exports, _ember, _highlightJs) {
  'use strict';

  function range(start, count) {
    return Array.apply(0, new Array(count)).map(function (element, index) {
      return index + start;
    });
  }

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['lang'],
    hasLineNumbers: true,
    lineNumbers: _ember['default'].computed('code', function () {
      var code = this.get('code');

      if (code) {
        return range(1, code.split('\n').length + 1);
      } else {
        return [];
      }
    }).readOnly(),

    highlight: _ember['default'].computed('code', 'lang', function () {
      var lang = this.get('lang');
      var code = this.get('code');

      if (!lang) {
        throw new Error('highlight-js lang property must be set');
      }
      if (lang === 'auto') {
        return (0, _highlightJs.highlightAuto)(code).value;
      } else {
        return (0, _highlightJs.highlight)(lang, code).value;
      }
    }).readOnly()
  });
});