define('ember-cli-imgix/components/imgix-image-element', ['exports', 'ember', 'ember-resize-mixin/main', 'ember-cli-imgix/mixins/imgix-path-behavior'], function (exports, _ember, _emberResizeMixinMain, _emberCliImgixMixinsImgixPathBehavior) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend(_emberCliImgixMixinsImgixPathBehavior['default'], _emberResizeMixinMain['default'], {
    tagName: 'img',
    layout: null,
    attributeBindings: ['src', 'crossorigin', 'style'],

    useParentWidth: true
  });
});