(function() {
/* global URI, bowser, Base64 */

define('uri.js', [], function() {
  'use strict';

  return { default: URI };
});

define('bowser', [], function() {
  'use strict';

  return { default: bowser };
});

define('base64', [], function() {
  'use strict';
  return { default: Base64 };
})

})();

