define('ember-resize-mixin/main', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    resizing: false,
    resizeDelay: 200,
    /**
     * Recursively traverse view tree looking for parent element that has a handler bound to
     */
    findResizableParentView: function findResizableParentView(parent) {
      if (_ember['default'].isNone(parent)) {
        return null;
      }
      if (parent && !parent.has('resize')) {
        return this.findResizableParentView(parent.get('parentView'));
      }
      return parent;
    },
    /**
     * Bind resizeHandler either to the parent view or window
     */
    _setupResizeHandlers: _ember['default'].on('didInsertElement', function () {
      var resizeHandler = this.get('_handleResize');
      var parent = this.findResizableParentView(this.get('parentView'));
      if (_ember['default'].isNone(parent)) {
        resizeHandler = _ember['default'].$.proxy(resizeHandler, this);
        // element doesn't have resizable views, so bind to the window
        _ember['default'].$(window).on("resize." + this.elementId, resizeHandler);
        this._resizeHandler = resizeHandler;
      } else {
        parent.on('resize', this, resizeHandler);
      }
    }),
    /**
     * Unbind from window if window binding was used
     */
    _removeResizeHandlers: _ember['default'].on('willDestroyElement', function () {
      if (this._resizeHandler) {
        _ember['default'].$(window).off("resize." + this.elementId, this._resizeHandler);
      }
    }),
    /**
     * Triggers resize events
     * Promise allows to chain events so async operations happen in sequence
     * @param event
     * @param promise
     * @private
     */
    _handleResize: function _handleResize(event, promise) {
      if (_ember['default'].isNone(promise)) {
        promise = _ember['default'].RSVP.resolve(null, "Resize handler");
      }
      if (!this.get('resizing')) {
        this.set('resizing', true);
        if (this.has('resizeStart')) {
          this.trigger('resizeStart', event);
        }
      }
      if (this.has('resize')) {
        this.trigger('resize', event, promise);
      }
      _ember['default'].run.debounce(this, this._endResize, event, this.get('resizeDelay'));
    },
    /**
     * Ends resizing by triggering resizeEnd event
     * @param event
     * @private
     */
    _endResize: function _endResize(event) {
      this.set('resizing', false);
      if (this.has('resizeEnd')) {
        this.trigger('resizeEnd', event);
      }
    }
  });
});